import React, { FC, useEffect, useState } from 'react';
import { ButtonBase, CircularProgress, makeStyles } from '@material-ui/core';
import Layout from '../../components/UI/Layout';
import ScreenTitle from '../../components/Shared/ScreenTitle';
import InfiniteScroll from 'react-infinite-scroller';
import EmptyList from '../../components/Shared/EmptyList';
import SearchBar from '../../components/Shared/SearchBar';
import { useLanguage } from 'languages/languageContext';
import { useAppDispatch, useAppSelector } from 'store';
import { getConfig } from 'config/config';
import { useHistory } from 'react-router';
import { clearUserContacts, deleteContacts, getUserContactsRequest } from 'store/reducers/contacts';
import { UserListItem } from 'components/UI/UserListItem';
import { imagePath } from 'utilities';
import { IonIcon } from 'components/UI/IonIcon';
import { Modal } from 'components/Shared/Modal/Modal';
import { Button } from 'components/shadcn/button';
import { UserType } from 'store/types/usersTypes';

const { theme } = getConfig();
const useStyles = makeStyles({
  container: {
    width: '100%',
    padding: '30px 0 30px 0',
    overflow: 'auto',
  },
  title: {
    textAlign: 'center',
  },
  inputWrapper: {
    padding: '10px 20px 0',
  },
});

const Contacts: FC = () => {
  const dispatch = useAppDispatch();
  const { contacts, contactsIsLoading, contactsCount } = useAppSelector((state) => state.contacts);
  const { screenTitles, privacy, btn } = useLanguage();
  const classes = useStyles({});
  const history = useHistory();
  const [state, setState] = useState({
    query: '',
    pageNumber: 1,
  });
  const [showDeleteContactModal, setShowDeleteContactModal] = useState(false);
  const [contact, setContact] = useState<UserType | null>(null);

  useEffect(() => {
    if (history.action === 'PUSH') {
      dispatch(clearUserContacts());
    }
  }, []);

  const getMoreUsersHandler = () => {
    const { pageNumber, query } = state;
    if (contactsIsLoading) return;
    if (contacts?.length === contactsCount) return;

    const userId = localStorage.getItem('userId');

    setState((c) => ({
      ...c,
      pageNumber: c.pageNumber + 1,
    }));

    if (userId) {
      dispatch(
        getUserContactsRequest({
          userId,
          page: pageNumber,
          limit: 20,
          searchkey: query,
        }),
      );
    }
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const userId = localStorage.getItem('userId');

    if (value.trim().length === 0) {
      clearSearchHandler();
    } else {
      setState({ pageNumber: 2, query: value });

      if (userId) {
        dispatch(
          getUserContactsRequest({
            userId,
            page: 1,
            limit: 20,
            searchkey: value,
          }),
        );
      }
    }
  };

  const clearSearchHandler = () => {
    const userId = localStorage.getItem('userId');
    dispatch(clearUserContacts());
    setState({
      query: '',
      pageNumber: 2,
    });

    if (userId) {
      dispatch(
        getUserContactsRequest({
          userId,
          page: 1,
          limit: 20,
          searchkey: '',
        }),
      );
    }
  };

  const handleCloseDeleteModal = () => setShowDeleteContactModal(false);

  const mappedItems = (
    <div>
      {contacts?.length > 0
        ? contacts.map((item) => (
            <div className="px-4">
              <UserListItem
                name={`${item.name} ${item.lastname}`}
                corpName={item?.corp[0].corpsname}
                description={item?.wohnort}
                image={imagePath(null, item.photo)}
                onClick={() => history.push(`/profile-details/${item?._id}`, { userId: item?._id })}
                rightBlockStyle="self-center cursor-pointer"
                rightBlock={
                  <ButtonBase
                    onClick={() => {
                      setContact(item);
                      setShowDeleteContactModal(true);
                    }}
                  >
                    <IonIcon name="trash-outline" size={32} color={theme.ERROR_PRIMARY} />
                  </ButtonBase>
                }
              />
            </div>
          ))
        : !contactsIsLoading && (
            <EmptyList title="Hier sind noch keine Einträge. Gehe über das Hauptmenü auf “Suche” → “Name”, um neue Kontakte zu finden. " />
          )}
    </div>
  );

  return (
    <Layout>
      <ScreenTitle title={screenTitles.contactsTitle} />
      <div className={classes.container}>
        <div className={classes.inputWrapper}>
          <SearchBar query={state.query} onChange={onChangeHandler} clearSearch={clearSearchHandler} />
        </div>
        <InfiniteScroll
          threshold={50}
          initialLoad={true}
          pageStart={1}
          loadMore={getMoreUsersHandler}
          hasMore={!(contacts?.length === contactsCount || state.query)}
          // this must work, but would be better if 'total' will be fixed on backend :)
          // hasMore={contactsCount === null ? true : contacts.length % 20 === 0}
          useWindow={true}
        >
          {mappedItems}
          {contactsIsLoading && (
            <div style={{ textAlign: 'center', padding: '10px 0' }} key={52}>
              <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={25} />
            </div>
          )}
        </InfiniteScroll>
      </div>
      <Modal
        isOpen={showDeleteContactModal}
        handleClose={handleCloseDeleteModal}
        message={`${contact?.name} ${contact?.lastname} aus der Kontaktliste entfernen? (Der Nutzer wird darüber nicht informiert).`}
        modalTitle="Kontakt löschen?"
      >
        <div className="px-8 gap-2 flex flex-col my-5">
          <Button
            onClick={() => {
              dispatch(deleteContacts({ userId: contact?._id }));
              handleCloseDeleteModal();
            }}
          >
            {privacy.settings.guestPost.yes}
          </Button>
          <Button className="bg-secondary-btn" onClick={handleCloseDeleteModal}>
            {btn.cancelBtn}
          </Button>
        </div>
      </Modal>
    </Layout>
  );
};

export default Contacts;
