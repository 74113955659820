import { useRef, useEffect, FC } from 'react';
import { Picker } from 'emoji-picker-element';
import de from 'emoji-picker-element/i18n/de';
import en from 'emoji-picker-element/i18n/en';
import it from 'emoji-picker-element/i18n/it';
import fr from 'emoji-picker-element/i18n/fr';
import hu from 'languages/emoji/hu';
import es from 'emoji-picker-element/i18n/es';
import ko from 'languages/emoji/ko';
import ja from 'languages/emoji/ja';
import zh from 'emoji-picker-element/i18n/zh_CN';
import pl from 'emoji-picker-element/i18n/pl';
import ru from 'emoji-picker-element/i18n/ru_RU';
import { makeStyles } from '@material-ui/styles';

import { useLanguage } from 'languages/languageContext';
import { getConfig } from 'config/config';

const i18n = {
  de,
  en,
  it,
  fr,
  hu,
  es,
  ko,
  ja,
  zh,
  pl,
  ru,
};

const { theme } = getConfig();

const useStyles = makeStyles({
  container: {
    width: '100%',
    '& emoji-picker': {
      width: '100%',
      '--background': theme.BACKGROUND_PRIMARY,
      '--border-color': 'transparent',
      '--border-radius': '3px',
      '--button-hover-background': theme.ACTIVE_INPUT,
      '--button-active-background': theme.ACTIVE_INPUT,
      '--indicator-color': theme.ACTIVE_INPUT,
      '--input-font-color': theme.TEXT_PRIMARY,
      '--input-padding': '4px 12px',
      '--input-border-radius': '90px',
      '--input-border-color': theme.BACKGROUND_SECONDARY,
      '--input-border-size': '1px',
      '--input-placeholder-color': theme.TEXT_SECONDARY,
      '--input-font-size': '15px',
      '--outline-size': '1px',
      '--outline-color': theme.ACTIVE_INPUT,
    },
  },
});

interface IEmojiPicker {
  isVisible: boolean;
  onEmojiClick?: (unicode: string) => void;
}

export const EmojiPicker: FC<IEmojiPicker> = ({ isVisible, onEmojiClick }) => {
  const styles = useStyles({});
  const { key2: locale } = useLanguage();
  const pickerContainerRef = useRef(null);

  useEffect(() => {
    let picker;

    if (isVisible && pickerContainerRef.current) {
      picker = new Picker({ emojiVersion: 15.0, locale, i18n: i18n[locale] });
      picker.addEventListener('emoji-click', handleEmojiClick);
      pickerContainerRef.current.appendChild(picker);
    }

    return () => {
      if (picker && pickerContainerRef.current) {
        picker.removeEventListener('emoji-click', handleEmojiClick);
        pickerContainerRef.current.removeChild(picker);
      }
    };
  }, [isVisible]);

  const handleEmojiClick = (event) => {
    const unicode = event.detail.unicode;
    if (onEmojiClick) {
      onEmojiClick(unicode);
    }
  };

  return (
    <>
      {isVisible && (
        <div
          className={`${styles.container} 
      
      `}
          ref={pickerContainerRef}
        ></div>
      )}
    </>
  );
};
