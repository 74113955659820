export default {
  categoriesLabel: '카테고리',
  emojiUnsupportedMessage: '브라우저가 컬러 이모지를 지원하지 않습니다.',
  favoritesLabel: '즐겨찾기',
  loadingMessage: '로딩 중…',
  networkErrorMessage: '이모지를 불러올 수 없습니다.',
  regionLabel: '이모지 선택기',
  searchDescription: '검색 결과가 표시되면 위 또는 아래 키로 선택하고 Enter 키로 선택하세요.',
  searchLabel: '검색',
  searchResultsLabel: '검색 결과',
  skinToneDescription: '확장되면 위 또는 아래 키로 선택하고 Enter 키로 선택하세요.',
  skinToneLabel: '피부 톤 선택 (현재 {skinTone})',
  skinTonesLabel: '피부 톤',
  skinTones: ['기본값', '밝은 톤', '중간 밝은 톤', '중간 톤', '중간 어두운 톤', '어두운 톤'],
  categories: {
    custom: '사용자 정의',
    'smileys-emotion': '스마일 및 감정',
    'people-body': '사람 및 신체',
    'animals-nature': '동물 및 자연',
    'food-drink': '음식 및 음료',
    'travel-places': '여행 및 장소',
    activities: '활동',
    objects: '물체',
    symbols: '기호',
    flags: '국기',
  },
};
