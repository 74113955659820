export default {
  categoriesLabel: 'カテゴリ',
  emojiUnsupportedMessage: 'お使いのブラウザはカラフルな絵文字に対応していません。',
  favoritesLabel: 'お気に入り',
  loadingMessage: '読み込み中…',
  networkErrorMessage: '絵文字を読み込めませんでした。',
  regionLabel: '絵文字ピッカー',
  searchDescription: '検索結果が表示されたら、上下キーで選択してEnterキーで決定します。',
  searchLabel: '検索',
  searchResultsLabel: '検索結果',
  skinToneDescription: '展開されたら、上下キーで選択してEnterキーで決定します。',
  skinToneLabel: 'スキントーンを選択 (現在のトーン: {skinTone})',
  skinTonesLabel: 'スキントーン',
  skinTones: ['デフォルト', 'ライト', 'ミディアムライト', 'ミディアム', 'ミディアムダーク', 'ダーク'],
  categories: {
    custom: 'カスタム',
    'smileys-emotion': 'スマイリーと感情',
    'people-body': '人と身体',
    'animals-nature': '動物と自然',
    'food-drink': '食べ物と飲み物',
    'travel-places': '旅行と場所',
    activities: '活動',
    objects: '物体',
    symbols: '記号',
    flags: '旗',
  },
};
