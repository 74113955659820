import React from 'react';
import { getConfig } from '../../../config/config';
import { AttachmentsGallery } from '../../../components/Shared/AttachmentsGallery';
import { fileType } from 'store/reducers/attachments';

const { theme } = getConfig();

type Props = {
  value: {
    pdf?: fileType[];
    video?: fileType[];
    image?: fileType[];
    link?: string;
  };
};

const ProfileAttachments: React.FC<Props> = ({ value }) => {
  if (!value) return <></>;
  if (!value.video && !value.image && !value.pdf) return <></>;

  const videos = value.video || [];
  const images = value.image || [];
  const documents = value.pdf || [];

  return (
    <>
      <div className="h-[1px] mb-8 mx-4 md:mx-8 " style={{ backgroundColor: theme.BACKGROUND_SECONDARY }} />
      <div className="overflow-hidden">
        <AttachmentsGallery videos={videos} images={images} documents={documents} showAsString={true} />
      </div>
    </>
  );
};
export default ProfileAttachments;
