import React from 'react';
import { useDispatch } from 'react-redux';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';

import { useLanguage } from 'languages/languageContext';
import { useAppSelector } from 'store';
import { UploadMedia } from 'components/Shared/UploadMedia';
import { RenderStateAttachments } from 'scenes/DialogPanel/components/RenderStateAttachments';
import { mediaActions, selectMediaState } from 'store/reducers/mediaSlice';
import { getConfig } from 'config/config';
import { Button } from 'components/shadcn/button';
import { cn } from 'utilities/utils';

const { theme } = getConfig();
const styles = createStyles({
  container: {
    position: 'sticky',
    width: '100%',
    bottom: 0,
    right: 0,
    padding: '10px 30px 10px 30px',
    zIndex: 10,
    boxSizing: 'border-box',
    boxShadow: '1px 0 5px 0 #ccc',
    backgroundColor: theme.BACKGROUND_PRIMARY,
  },
  input: {
    fontFamily: 'Poppins',
    padding: '10px 0',
    border: 'none',
    outline: 'none',
    fontSize: 15,
    resize: 'none',
    width: '100%',
    '&::placeholder': {
      fontSize: 15,
      fontFamily: 'Roboto',
    },
  },
  '@media(max-width: 1024px)': {
    container: {
      right: 0,
      padding: '10px',
    },
  },
  '@media(max-width: 650px)': {
    container: {
      width: '100%',
      left: 0,
      right: 0,
      padding: '10px',
    },
  },
});

interface Props extends WithStyles<typeof styles> {
  updateMode: boolean;
  disabled: boolean;
  submitHandler: () => void;
  label?: string;
  showRemoveEvent: () => void;
  loading?: boolean;
}

const SubmitEvent: React.FC<Props> = ({
  classes,
  updateMode = false,

  showRemoveEvent,
  submitHandler = () => {},
  disabled,
  label,
  loading,
}) => {
  const { btn } = useLanguage();
  const dispatch = useDispatch();

  const { documents, videos, images, loading: mediaLoading } = useAppSelector(selectMediaState);
  const attachments = { documents, videos, images };

  const deleteAttachment = (type: 'documents' | 'images' | 'videos', id: number) => {
    dispatch(mediaActions.deleteAttachment({ type, id }));
  };

  const onAddEvent = () => {
    submitHandler();
  };

  const addPictureDisabled = images.length >= 1;
  const addPDFDisabled = documents.length >= 1;
  const addVideoDisabled = true;

  return (
    <div className={classes.container}>
      <div className={'flex flex-row gap-2'}>
        <UploadMedia
          addPDFDisabled={addPDFDisabled}
          addPictureDisabled={addPictureDisabled}
          addVideoDisabled={addVideoDisabled}
          multiple={false}
        />
        <Button
          onClick={onAddEvent}
          disabled={mediaLoading || loading}
          className={cn((loading || disabled) && 'bg-[#ccc]')}
        >
          {btn.saveBtn}
        </Button>
        {updateMode && (
          <Button className="bg-secondary-btn" onClick={showRemoveEvent}>
            {label || btn.cancelBtn}
          </Button>
        )}
        <div />
      </div>

      <RenderStateAttachments currentAttachments={attachments} deleteAttachment={deleteAttachment} viewType="column" />
    </div>
  );
};

export default withStyles(styles)(SubmitEvent);
