import React, { FC, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { ArrowDownward } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import { getConfig } from 'config/config';
import { DownloadButton } from './DownloadButton';
import { TMediaSize } from './AttachmentsGallery';
import { fileType } from 'store/reducers/attachments';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { cn } from 'utilities/utils';

const { theme } = getConfig();
export const VideoPlayer: FC<{ video: Partial<fileType>; size?: TMediaSize; showAsString?: boolean }> = ({
  video,
  size,
  showAsString,
}) => {
  const [videoSize, setVideoSize] = useState<null | string>(null);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const srcUri = video?.url;

  const { mediaWidth, mediaHeight } = useWindowDimensions();

  useEffect(() => {
    fetch(srcUri, { method: 'HEAD' })
      .then((res) => {
        const videoSize = Number(res.headers.get('Content-Length') || 0);
        const string = `${Math.round(videoSize / Math.pow(10, 4)) / 100}MB`;
        setVideoSize(string);
      })
      .catch((e) => console.log('FAILED TO FETCH VIDEO SIZE :', e));
  }, []);

  const LoadView = (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: theme.BUTTON_PRIMARY,
        width: mediaHeight[size] / 3.5,
        height: mediaHeight[size] / 3.5,
        borderRadius: 100,
      }}
    >
      <ArrowDownward fontSize={size === 'lg' ? 'large' : 'small'} htmlColor={'#fff'} />
      <p style={{ color: '#fff', fontWeight: 'bold', fontSize: size === 'lg' ? 14 : 10 }}>{videoSize}</p>
    </Box>
  );

  return (
    <div
      style={{ width: 'auto', height: 'auto', position: 'relative' }}
      className={cn(showAsString && 'self-start flex flex-row items-center mb-2 rounded-lg')}
    >
      {!showAsString && videoIsPlaying && (
        <DownloadButton {...video} style={{ top: 10, right: 10, position: 'absolute' }} />
      )}
      <ReactPlayer
        url={video?.url}
        controls={true}
        playing={true}
        width={showAsString ? 80 : mediaWidth[size]}
        height={showAsString ? 80 : mediaHeight[size]}
        playIcon={showAsString ? undefined : LoadView}
        onPlay={() => setVideoIsPlaying(true)}
        light={video?.thumb}
      />
      <span className="ml-4">{video.fileName}</span>
    </div>
  );
};
