import { useState, useEffect } from 'react';

export default function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    const mediaWidth = {
      xs: window.innerWidth <= 650 ? window.innerWidth * 0.27 : window.innerWidth <= 1024 ? 126 : 150,
      sm: 180,
      lg: window.innerWidth >= 1024 ? 598 : 450,
    };

    const mediaHeight = {
      xs: window.innerWidth <= 650 ? window.innerWidth * 0.27 : window.innerWidth <= 1024 ? 126 : 150,
      sm: 180,
      lg: window.innerWidth >= 1024 ? 360 : 253,
    };
    return {
      width,
      height,
      mediaWidth,
      mediaHeight,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
}
