export const listenForOutsideClicks = (
  listening: boolean,
  setListening: (isListening: boolean) => void,
  menuRef: React.MutableRefObject<HTMLElement | null>,
  setIsOpen: (isOpen: boolean) => void,
): (() => void) => {
  return () => {
    if (listening) return;
    if (!menuRef?.current) return;
    setListening(true);
    [`click`, `touchstart`].forEach((type) => {
      document.addEventListener(type, (evt) => {
        if (menuRef?.current?.contains(evt.target as Node)) return;
        setIsOpen(false);
      });
    });
  };
};
