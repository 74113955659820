export default {
  categoriesLabel: 'Kategóriák',
  emojiUnsupportedMessage: 'A böngészője nem támogatja a színes emojikat.',
  favoritesLabel: 'Kedvencek',
  loadingMessage: 'Betöltés…',
  networkErrorMessage: 'Nem sikerült betölteni az emojikat.',
  regionLabel: 'Emoji választó',
  searchDescription:
    'Ha keresési találatok érhetők el, használja a fel és le nyilakat a kiválasztáshoz, majd az Enter-t a választáshoz.',
  searchLabel: 'Keresés',
  searchResultsLabel: 'Keresési találatok',
  skinToneDescription: 'Kibontva használja a fel és le nyilakat a kiválasztáshoz, majd az Enter-t a választáshoz.',
  skinToneLabel: 'Válasszon bőrszínt (jelenleg {skinTone})',
  skinTonesLabel: 'Bőrszínek',
  skinTones: ['Alapértelmezett', 'Világos', 'Közepesen világos', 'Közepes', 'Közepesen sötét', 'Sötét'],
  categories: {
    custom: 'Egyéni',
    'smileys-emotion': 'Mosolygók és hangulatjelek',
    'people-body': 'Emberek és test',
    'animals-nature': 'Állatok és természet',
    'food-drink': 'Ételek és italok',
    'travel-places': 'Utazás és helyek',
    activities: 'Tevékenységek',
    objects: 'Tárgyak',
    symbols: 'Szimbólumok',
    flags: 'Zászlók',
  },
};
