import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import {
  TextareaAutosize,
  ButtonBase,
  CircularProgress,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import TagFaces from '@material-ui/icons/TagFaces';

import { withConfig } from 'config/';
import Button from 'components/UI/Button';
import { useLanguage } from 'languages/languageContext';
import { useAppSelector } from 'store';
import { getConfig } from 'config/config';
import { IonIcon } from 'components/UI/IonIcon';
import { getLocalStorage } from 'utilities/localStorageHandler';
import AlertModal from 'components/Shared/AlertModal';
import { RenderStateAttachments } from 'scenes/DialogPanel/components/RenderStateAttachments';
import { mediaActions } from 'store/reducers/mediaSlice';
import { UploadMedia } from 'components/Shared/UploadMedia';
import { EmojiPicker } from 'components/UI/EmojiPicker';
import { listenForOutsideClicks } from 'utilities/listenForOutsideClicks';

const { theme } = getConfig();
const styles = createStyles({
  container: {
    position: 'sticky',
    bottom: 0,
    width: '600px',
    padding: '10px',
    zIndex: 10,
    boxSizing: 'border-box',
    boxShadow: '1px 0 5px 0 #ccc',
    overflow: 'hidden',
  },
  inputWrapper: {
    position: 'relative',
    flexGrow: 1,
    margin: '0 10px',
    boxSizing: 'border-box',
  },
  input: {
    padding: '5px 0',
    border: 'none',
    outline: 'none',
    fontSize: 15,
    resize: 'none',
    width: '100%',
    '&::placeholder': {
      fontSize: 15,
      fontFamily: 'Roboto',
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6px',
    textAlign: 'center',
    borderRadius: 50,
    color: '#fff',
  },
  buttonsWrapper: {
    // width: '30%',
    display: 'flex',
    alignItems: 'center',
  },
  modalComment: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    margin: '0px 20px',
  },
  '@media(max-width: 1024px)': {
    container: {
      width: '450px',
    },
  },
  '@media(max-width: 650px)': {
    container: {
      width: '100%',
      left: 0,
      right: 0,
      padding: '10px',
    },
    inputWrapper: {
      paddingLeft: 10,
    },
  },
  emojiButton: {
    background: theme.BACKGROUND_SECONDARY,
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 10,
  },
});

interface Props extends WithStyles<typeof styles> {
  event: any;
  sendComment: ({ content, sendEmailComment }: { content: string; sendEmailComment: string }) => void;
}

const EventToolbar: React.FC<Props> = ({ classes, event, sendComment }) => {
  const { post, eventLabels } = useLanguage();
  const dispatch = useDispatch();
  const { loading, loadingCounter, isLoadingAllAttachments, ...attachments } = useAppSelector((state) => state.media);
  const hasAttachments = Object.values(attachments).findIndex((el) => el.length) !== -1 && !loading;
  const userId = getLocalStorage('userId');
  const owner = userId === event?.sender;
  const acceptedGuests = event?.acceptedGuests?.length + event?.members?.length;

  const [content, setContent] = useState<string>('');
  const [modalComment, setModalComment] = useState<boolean>(false);
  const [sendEmailComment, setSendEmailComment] = useState<string>('organizers-only');

  const showModalComment = () => setModalComment(true);
  const closeModalComment = () => setModalComment(false);

  const handleChangeSendEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendEmailComment(event.target.value);
  };

  const onAddComment = () => {
    sendComment({ content, sendEmailComment });
    setContent('');
    closeModalComment();
    setSendEmailComment('organizers-only');
  };

  const handleSendComment = () => {
    if (owner || !acceptedGuests) {
      return onAddComment();
    }
    return showModalComment();
  };

  const deleteAttachment = (type: 'documents' | 'images' | 'videos', id: number) => {
    dispatch(mediaActions.deleteAttachment({ type, id }));
  };

  // Emoji picker handling
  const [isPickerVisible, setPickerVisible] = useState(false);
  const [listening, setListening] = useState(false);

  const cursorPositionRef = useRef(0);
  const toolbarRef = useRef(null);
  const valueRef = useRef(content);

  const handleEmojiClick = useCallback(
    (unicode: string) => {
      const currentValue = valueRef.current;
      const currentCursorPosition = cursorPositionRef.current;

      let newValue = `${currentValue.slice(0, currentCursorPosition)}${unicode}${currentValue.slice(
        currentCursorPosition,
      )}`;

      newValue = limitValueLenght(newValue);

      setContent(newValue);
      valueRef.current = newValue;
      cursorPositionRef.current += unicode.length;
    },
    [setContent],
  );

  const updateCursorPosition = useCallback((e) => {
    cursorPositionRef.current = e.currentTarget.selectionStart;
  }, []);

  const handleTextareaChange = useCallback(
    (e) => {
      e.target.value = limitValueLenght(e.target.value);
      setContent(e.target.value);
      valueRef.current = e.target.value;
      updateCursorPosition(e);
    },
    [setContent, updateCursorPosition],
  );

  const limitValueLenght = (value?: string) => value?.slice(0, 20000) || '';

  useEffect(listenForOutsideClicks(listening, setListening, toolbarRef, setPickerVisible));

  return (
    <div ref={toolbarRef} className={classes.container} style={{ background: theme.BACKGROUND_PRIMARY }}>
      <RenderStateAttachments currentAttachments={attachments} deleteAttachment={deleteAttachment} viewType="column" />
      <EmojiPicker isVisible={isPickerVisible} onEmojiClick={handleEmojiClick} />
      <div style={{ width: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
        <button className={classes.emojiButton} onClick={() => setPickerVisible(!isPickerVisible)}>
          <TagFaces style={{ color: theme.BUTTON_PRIMARY, fontSize: 28 }} />
        </button>
        <UploadMedia />
        <div className={classes.inputWrapper}>
          <TextareaAutosize
            rowsMax={6}
            style={{ background: theme.BACKGROUND_PRIMARY, fontFamily: 'Roboto' }}
            className={classes.input}
            placeholder={post.sendComment}
            value={content}
            onChange={handleTextareaChange}
            onClick={updateCursorPosition}
            onFocus={updateCursorPosition}
          />
          {content.length < 19999 || (
            <span style={{ color: theme.ERROR_PRIMARY, marginBottom: 10 }}>Maximal 20.000 Zeichen</span>
          )}
        </div>
        <div className={classes.buttonsWrapper}>
          <ButtonBase
            disabled={!(content || hasAttachments)}
            onClick={() => {
              handleSendComment();
              valueRef.current = '';
              setPickerVisible(false);
              setListening(false);
            }}
            className={classes.button}
            style={{ background: !(content || hasAttachments) ? '#ccc' : theme.BUTTON_PRIMARY }}
          >
            <IonIcon name="paper-plane-outline" color="#FFF" size={28} style={{ height: 29, width: 29 }} />
          </ButtonBase>
        </div>
      </div>

      <AlertModal
        showModal={modalComment}
        title={eventLabels.modalComment.title}
        message={eventLabels.modalComment.message}
        showCloseBtn
        onClose={() => {
          closeModalComment();
          setSendEmailComment('organizers-only');
        }}
      >
        <div className={classes.modalComment}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={sendEmailComment}
              onChange={handleChangeSendEmail}
            >
              <FormControlLabel
                value="organizers-only"
                control={<Radio color="primary" />}
                label={eventLabels.modalComment.optionNone}
              />
              <FormControlLabel
                value="all"
                control={<Radio color="primary" />}
                label={eventLabels.modalComment.optionAll}
              />
            </RadioGroup>
          </FormControl>
          <Button onClick={onAddComment} label={eventLabels.modalComment.button} background={theme.BUTTON_SECONDARY} />
        </div>
      </AlertModal>
    </div>
  );
};

export default withStyles(styles)(withConfig(EventToolbar));
