import { FC } from 'react';
import DocumentsGallery from './DocumentsGallery';
import PostGallery from './PostGallery';
import { VideoPlayer } from './VideoPlayer';
import { fileType } from 'store/reducers/attachments';

export type TMediaSize = 'xs' | 'sm' | 'lg';

type Props = {
  documents: Partial<fileType>[];
  videos: Partial<fileType>[];
  images: Partial<fileType>[];
  size?: TMediaSize;
  flexDirection?: 'row' | 'col';
  showAsString?: boolean;
};

export const AttachmentsGallery: FC<Props> = ({
  documents,
  videos,
  images,
  size = 'lg',
  flexDirection = 'col',
  showAsString,
}) => {
  return (
    <div className={`flex flex-${flexDirection} gap-x-2 items-center`}>
      {!!images?.length && <PostGallery images={images} videos={videos} size={size} showAsString={showAsString} />}
      {videos?.map((video) => (
        <>
          <VideoPlayer video={video} size={size} showAsString={showAsString} />
          <div className="h-[1px]" />
        </>
      ))}
      {!!documents?.length && <DocumentsGallery files={documents} size={size} showAsString={showAsString} />}
    </div>
  );
};
