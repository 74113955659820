import React from 'react';
import { withConfig } from '../../../config';
import { CorpsProps } from '../model';
import SelectModal from '../../../components/Shared/SelectModal';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import TouchableInput from '../../../components/UI/TouchableInput';
import Input from '../../../components/UI/Input';
import { Box, ButtonBase } from '@material-ui/core';
import RemoveCircle from '@material-ui/icons/HighlightOff';
import DatePicker from '../../../components/Shared/DatePicker/DatePicker';
import SearchableInput from '../../../components/Shared/SearchableInput';
import SearchableModal from '../../../components/Shared/SearchableModal';
import AlertModal from '../../../components/Shared/AlertModal';
import Button from '../../../components/UI/Button';
import { configDate } from '../../../utilities/';
import { withLanguage } from 'languages';
import { translationsType } from 'languages/languageContext';
import { MultipleSelectModal } from 'components/Shared/MultipleSelectModal';
import { MultipleModalItem } from 'components/UI/MultipleModalItem';
import { getCorpsToEnter } from 'api/corps';
import { getConfig } from 'config/config';
import { RootState } from 'store';
import { ConnectedProps, connect } from 'react-redux';
import { MultipleSearchableInput } from 'components/Shared/MultipleSearchableInput';
import { CorpsModel } from '../ProfileCorps';

const { theme } = getConfig();
const styles = createStyles({
  container: {
    position: 'relative',
    width: 420,
    padding: '0 0 20px',
    boxSizing: 'border-box',
    borderBottom: '1px solid',
    marginBottom: 20,
  },
  addButtonContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  '@media(max-width: 650px)': {
    container: {
      width: '100%',
    },
  },
  unverified: {
    color: '#bbb',
    paddingLeft: 5,
  },
});

type ReduxProps = ConnectedProps<typeof connector>;

interface Props extends WithStyles<typeof styles>, CorpsProps, ReduxProps {
  language: translationsType;
  allCorps: CorpsModel[];
}

interface State {
  [key: string]: any;
}

class Corps extends React.Component<Props, State> {
  state = {
    currentCorps: '',
    corpsname: {
      value: '',
      isFocused: false,
    },
    selectedCorps: '',
    status: 'keine',
    charge: [],
    verified: 0,
    createRequest: false,
    rezeptionsdatum: '',
    corpsList: [],
    alertModalIsOpen: false,
    corpsModalIsOpen: false,
    statusModalIsOpen: false,
    chargeModalIsOpen: false,
    functionModalIsOpen: false,
    rezeptionsdatumModalIsOpen: false,
    codeIsFocused: false,
    type: 'exist',
    code: {
      value: '',
      isFocused: false,
    },
    logincode: {
      value: '',
      isFocused: false,
    },
    mentor: {
      value: '',
      isFocused: false,
    },
    mentee: {
      value: '',
      isFocused: false,
    },
    function: {
      value: '',
      isFocused: false,
    },
    searchableCharge: '',
    queryCorps: '',
  };

  componentDidMount() {
    this.initState();
  }

  componentDidUpdate(prevProps: Props) {
    const { corps } = this.props;

    if (prevProps.corps !== corps) {
      this.initState();
    }

    // if (prevProps.corpsNames !== corpsNames) {
    //   this.configCorps();
    // }
  }

  initState = () => {
    const { corps } = this.props;

    if (corps) {
      this.setState({
        // corpsname: corps.corpsname,
        corpsname: {
          value: corps.corpsname,
          isFocused: false,
        },
        type: corps.type || 'exist',
        status: corps.status || '',
        charge: corps.charge || [],
        rezeptionsdatum: corps.rezeptionsdatum ? new Date(corps.rezeptionsdatum) : '',
        verified: corps.verified || 0,
        createRequest: corps.createRequest || false,
        logincode: {
          value: corps.logincode || '',
          isFocused: false,
        },
      });
    }
  };

  setSearchedValueHandler = (): void => {
    const { corpsStateHandler, corps } = this.props;
    const { currentCorps } = this.state;

    this.setState({
      corpsname: currentCorps,
      corpsModalIsOpen: false,
    });

    corpsStateHandler(currentCorps, 'corpsname', corps._id);
  };

  selectItemHandler = (value: string, name: string) => {
    const { corpsStateHandler, corps } = this.props;
    let newValue: string | string[] = '';
    if (name === 'charge') newValue = [value, ...this.state?.charge];

    if (name !== 'charge') newValue = value;
    this.setState({
      ...this.state,
      [name]: newValue,
      selectedCorps: name === 'currentCorps' ? newValue : '',
    });

    if (name !== 'currentCorps') {
      corpsStateHandler(newValue, name, corps._id);
    }
  };

  deleteItemHandler = (value: string, name: 'charge' | string) => {
    const { corpsStateHandler, corps } = this.props;

    let newItem = this.state[name].filter((item) => item !== value);
    this.setState({
      ...this.state,
      [name]: newItem,
    });
    if (name !== 'currentCorps') {
      corpsStateHandler(newItem, name, corps._id);
    }
  };

  dateHandler = (inputName: string, date: any) => {
    const { corpsStateHandler, corps } = this.props;

    this.setState({ rezeptionsdatum: date, rezeptionsdatumModalIsOpen: false });
    corpsStateHandler(date, 'rezeptionsdatum', corps._id);
  };

  modalHandler = (name: string) => {
    let modalName;
    switch (name) {
      case 'status':
        modalName = 'statusModalIsOpen';
        break;
      case 'charge':
        modalName = 'chargeModalIsOpen';
        this.setState({ searchableCharge: '' });
        break;
      case 'rezeptionsdatum':
        modalName = 'rezeptionsdatumModalIsOpen';
        break;
      case 'corpsname':
        modalName = 'corpsModalIsOpen';
        this.setState({ queryCorps: '' });
        break;
      case 'alert':
        modalName = 'alertModalIsOpen';
        break;
      case 'function':
        modalName = 'functionModalIsOpen';
        break;
      default:
        modalName = 'statusModalIsOpen';
        break;
    }

    this.setState({
      [modalName]: !this.state[modalName],
      currentCorps: '',
      selectedCorps: '',
    });
  };

  focusHandler = (name: 'logincode' | 'corpsname'): void => {
    this.setState({
      [name]: {
        ...this.state[name],
        isFocused: !this.state[name].isFocused,
      },
    });
  };

  onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>, name: string): void => {
    const { value } = e.target;
    // if (name === 'currentCorps') {
    //   this.props.getCorpsNamesDebounced(value);
    // }
    this.setState({
      [name]: value,
    });
  };

  onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, name: 'corpsname' | 'logincode') => {
    e.persist();
    const { value } = e.target;
    const { corpsStateHandler, corps } = this.props;

    this.setState({
      [name]: {
        ...this.state[name],
        value,
      },
    });

    corpsStateHandler(value, name, corps._id);
  };

  onRemoveHandler = () => {
    const { removeCorpsHandler, corps } = this.props;

    if (this.state.corpsname) {
      this.setState({
        alertModalIsOpen: true,
      });
    } else {
      removeCorpsHandler(corps._id);
    }
  };

  removeCorps = () => {
    const { removeCorpsHandler, corps } = this.props;

    setTimeout(() => {
      removeCorpsHandler(corps._id);
    }, 1);
  };

  render(): JSX.Element {
    const { classes, lastElem, elemIndex, corps, config, language, corpsFunction, corpsStatuses, allCorps } =
      this.props;
    const { profileKorp, signUpManual, btn } = language;
    const { rezeptionsdatum, logincode, codeIsFocused } = this.state;
    const editedDate = configDate(rezeptionsdatum, 'month');
    const firstElem = elemIndex === 0;

    // const disabled = !this.state.verified && this.state.type === 'exist';
    const disabled = !this.state.verified && !this.state.createRequest;
    // @ts-ignore
    const filteredFunctions = allCorps.find((corp) => corp.name === corps.corpsname)?.functionSet || [];

    return (
      <React.Fragment>
        <div
          className={classes.container}
          style={{
            borderBottomColor: !lastElem ? theme.BACKGROUND_SECONDARY : 'transparent',
            paddingTop: !firstElem ? 30 : 0,
          }}
        >
          {!firstElem && (
            <div
              className={classes.addButtonContainer}
              style={{ top: this.state.corpsname ? 0 : -10, justifyContent: !disabled ? 'flex-end' : 'space-between' }}
            >
              {disabled && <span className={classes.unverified}>Warten auf Freischaltung</span>}
              {corps.allowUnsubscribe && (
                <ButtonBase
                  style={{
                    borderRadius: 20,
                    fontSize: 30,
                    background: theme.BACKGROUND,
                  }}
                  onClick={this.onRemoveHandler}
                >
                  <RemoveCircle style={{ color: theme.BUTTON_SECONDARY }} fontSize="inherit" />
                </ButtonBase>
              )}
            </div>
          )}
          {corps.type === 'create' || corps.type === 'requestNew' ? (
            <Input
              value={this.state.corpsname.value}
              placeholder={`Projektname`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, 'corpsname')}
              isFocused={this.state.corpsname.isFocused}
              onBlur={() => this.focusHandler('corpsname')}
              onFocus={() => this.focusHandler('corpsname')}
              hint={
                corps.type === 'create'
                  ? 'Es wird ein neuer Eintrag angelegt.'
                  : corps.type === 'requestNew'
                  ? 'Die Erstellung des neuen Eintrags wird beantragt.'
                  : ''
              }
            />
          ) : (
            <TouchableInput
              disabled={firstElem || disabled}
              onClick={() => this.modalHandler('corpsname')}
              value={this.state.corpsname.value}
              placeholder={profileKorp.verband}
            />
          )}
          {corps.type === 'requestExisting' && (
            <Input
              placeholder={signUpManual.loginCode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = e.target;
                return this.onChangeHandler(e, 'logincode');
              }}
              autoCapitalize="none"
              value={logincode.value}
              isFocused={logincode.isFocused}
              onBlur={() => this.focusHandler('logincode')}
              onFocus={() => this.focusHandler('logincode')}
              disabled={disabled}
            />
          )}
          {/* <TouchableInput onClick={() => this.modalHandler('status')} value={this.state.status} placeholder="Status" /> */}

          <TouchableInput
            onClick={() => this.modalHandler('charge')}
            value={undefined}
            placeholder={profileKorp.function}
            disabled={disabled}
          />
          {this.state?.charge?.length
            ? this.state?.charge?.map((item, idx) => (
                <MultipleModalItem key={idx} item={item} deleteItemHandler={this.deleteItemHandler} name="charge" />
              ))
            : null}

          <Box height={15} />
          {config.WITH_CORPS_STATUS && (
            <TouchableInput
              onClick={() => this.modalHandler('status')}
              value={this.state.status}
              placeholder={'Status'}
              disabled={disabled}
            />
          )}
          <TouchableInput
            onClick={() => this.modalHandler('rezeptionsdatum')}
            value={editedDate}
            placeholder={profileKorp.dateOfJoin}
            disabled={disabled}
          />
        </div>

        <SearchableModal
          modalOpen={this.state.corpsModalIsOpen}
          modalTitle={language.searchModal.pleaseSelect}
          onClick={() => this.modalHandler('corpsname')}
          setValueHandler={() => this.modalHandler('corpsname')}
          value={this.state.selectedCorps}
        >
          <MultipleSearchableInput
            data={allCorps || []}
            onChange={(e: any) => this.setState({ queryCorps: e.target.value })}
            inputName="currentCorps"
            values={[this.state.corpsname.value]}
            value={this.state.queryCorps}
            onSelect={(value: any) => {
              this.setState({
                corpsname: {
                  ...this.state.corpsname,
                  value: value.name,
                },
              });
              this.props.corpsStateHandler(value.name, 'corpsname', corps._id);
            }}
            deleteItemHandler={(value: string) => this.deleteItemHandler(value, 'corps')}
            currentName=""
          />
        </SearchableModal>
        <SelectModal
          modalIsOpen={this.state.statusModalIsOpen}
          modalTitle="Status"
          modalHandler={() => this.modalHandler('status')}
          selectItemHandler={this.selectItemHandler}
          data={corpsStatuses}
          modalName="status"
        />
        {this.state.chargeModalIsOpen && (
          <SearchableModal
            onClick={() => this.modalHandler('charge')}
            modalTitle={language.searchModal.pleaseSelect}
            setValueHandler={() => this.modalHandler('charge')}
            modalOpen={this.state.chargeModalIsOpen}
            value={this.state.searchableCharge}
          >
            <MultipleSearchableInput
              data={filteredFunctions}
              values={this.state.charge}
              value={this.state.searchableCharge}
              onChange={(e: any) => this.setState({ searchableCharge: e.target.value })}
              onSelect={(value: any) => this.selectItemHandler(value, 'charge')}
              deleteItemHandler={(value: string) => this.deleteItemHandler(value, 'charge')}
              currentName=""
            />
          </SearchableModal>
        )}
        <DatePicker
          date={new Date()}
          dateModalIsOpen={this.state.rezeptionsdatumModalIsOpen}
          dateHandler={this.dateHandler}
          closeModalHandler={() => this.modalHandler('rezeptionsdatum')}
          pickerType="month"
          // Date picker config
          dateFormat="MM/yyyy"
          showMonthYearPicker
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          fixedHeight={true}
          startOpen={true}
          inline
          shouldCloseOnSelect={false}
        />
        <AlertModal
          showModal={this.state.alertModalIsOpen}
          onClose={() => this.modalHandler('alert')}
          message={`Wollen Sie Ihre Mitgliedschaft im ${this.state.corpsname.value} wirklich löschen?`}
        >
          <Button
            onClick={() => this.modalHandler('alert')}
            label={btn.cancelBtn}
            width={120}
            background={theme.BUTTON_SECONDARY}
          />
          <Button
            onClick={() => {
              this.modalHandler('alert');
              this.removeCorps();
            }}
            label="OK"
            width={120}
          />
        </AlertModal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  corpsFunction: state.common.data.corpsFunction,
  corpsStatuses: state.common.data.corpsStatuses,
});

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(withLanguage(withConfig(Corps))));
