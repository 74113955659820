import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { ButtonBase } from '@material-ui/core';
import { Config, withConfig } from '../../config';
import { ProfileBerufProps } from './model';
import BerufHeading from './components/BerufHeading';
import Input from '../../components/UI/Input';
import TouchableInput from '../../components/UI/TouchableInput';
import SelectModal from '../../components/Shared/SelectModal';
import Button from '../../components/UI/Button';
import DatePicker from '../../components/Shared/DatePicker/DatePicker';
import Loader from '../../components/UI/Loader';
import ProfileScale from '../../components/UI/ProfileScale';
import { showResponse } from '../../store/actions/response';
import { updateUserRequest } from '../../store/actions/users';
import { employmentType, activityType, seniorityType, branchType, specialityType, configDate } from '../../utilities/';
import Beruf from './components/Beruf';
import { withLanguage } from 'languages';
import { translationsType } from 'languages/languageContext';
import { dateToString } from 'utilities/dateToString';
import { MultipleModalItem } from 'components/UI/MultipleModalItem';
import { MultipleSelectModal } from 'components/Shared/MultipleSelectModal';

const styles = createStyles({
  container: {
    width: 420,
    boxSizing: 'border-box',
  },
  berufWrapper: {
    marginBottom: 40,
  },
  inputsWrapper: {
    width: '100%',
  },
  vonDateWrapper: {
    position: 'relative',
    width: '100%',
  },
  vonDateButtonWrapper: {
    position: 'absolute',
    right: 20,
  },
  vonDateButton: {
    borderRadius: 3,
    cursor: 'pointer',
  },
  vonDateLabel: {
    fontSize: 14,
    fontFamily: 'Poppins',
  },
  updateButtonWrapper: {
    width: '100%',
    border: '1px gray',
  },
  addBerufWrapper: {
    position: 'relative',
    width: '100%',
    border: '1 gray',
    paddingBottom: 40,
  },
  addBerufButton: {
    position: 'absolute',
    top: -31,
    width: 40,
    height: 40,
    border: 'none',
    borderRadius: 20,
  },
  '@media(max-width: 650px)': {
    container: {
      width: '100%',
      padding: '0 10px',
    },
  },
});

interface Props extends WithStyles<typeof styles>, ProfileBerufProps {
  config: Config;
  language: translationsType;
}

class ProfileBeruf extends React.Component<Props> {
  state = {
    name: {
      value: '',
      isFocused: false,
      isValid: false,
    },
    company: {
      value: '',
      isFocused: false,
      isValid: false,
    },
    description: {
      value: '',
      isFocused: false,
      isValid: false,
    },
    website: {
      value: '',
      isFocused: false,
      isValid: false,
    },
    employmentType: '',
    activity: [],
    careerLevel: '',
    industry: '',
    domain: '',
    start: '',
    end: '',
    beschaeftigungsartModalIsOpen: false,
    taetigkeitsfeldModalIsOpen: false,
    karrierestufeModalIsOpen: false,
    brancheModalIsOpen: false,
    bereichModalIsOpen: false,
    jobStartModalIsOpen: false,
    jobEndModalIsOpen: false,
    additionalBerufList: [],
  };

  componentDidMount() {
    this.intiState();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (prevProps.user !== user) {
      this.intiState();
    }
  }

  intiState = () => {
    const { user } = this.props;
    const mainBeruf = user.beruf?.[0];
    const additionalBeruf = user.beruf?.slice(1);
    if (mainBeruf) {
      this.setState({
        name: {
          value: mainBeruf.name || '',
        },
        company: {
          value: mainBeruf.company || '',
        },
        description: {
          value: mainBeruf.description || '',
        },
        website: {
          value: mainBeruf.website || '',
        },
        employmentType: mainBeruf.employmentType || '',
        activity:
          mainBeruf.activity && typeof mainBeruf.activity === 'string'
            ? [mainBeruf.activity]
            : mainBeruf.activity
            ? mainBeruf.activity
            : [],
        careerLevel: mainBeruf.careerLevel || '',
        industry: mainBeruf.industry || '',
        domain: mainBeruf.domain || '',
        start: mainBeruf.start || '',
        end: mainBeruf.end || '',
        additionalBerufList: additionalBeruf,
      });
    }
  };

  modalHandler = (name: string) => {
    let modalName;
    switch (name) {
      case 'employmentType':
        modalName = 'beschaeftigungsartModalIsOpen';
        break;
      case 'activity':
        modalName = 'taetigkeitsfeldModalIsOpen';
        break;
      case 'careerLevel':
        modalName = 'karrierestufeModalIsOpen';
        break;
      case 'industry':
        modalName = 'brancheModalIsOpen';
        break;
      case 'domain':
        modalName = 'bereichModalIsOpen';
        break;
      case 'start':
        modalName = 'jobStartModalIsOpen';
        break;
      case 'end':
        modalName = 'jobEndModalIsOpen';
        break;
      default:
        modalName = 'beschaeftigungsartModalIsOpen';
        break;
    }

    this.setState({
      [modalName]: !this.state[modalName],
    });
  };

  focusHandler = (inputName: string): void => {
    this.setState({
      [inputName]: {
        ...this.state[inputName],
        isFocused: !this.state[inputName].isFocused,
      },
    });
  };

  onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, inputName: string) => {
    const { value } = e.target;

    this.setState({
      [inputName]: {
        value,
        isFocused: true,
      },
    });
  };

  selectItemHandler = (value: string, name: string) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  setDefaultDate = () => {
    this.setState({
      end: 'Bis heute',
    });
  };

  dateHandler = (inputName: string, value: string) => {
    // const date = configDate(value, 'month');

    this.setState({
      [inputName]: value,
    });

    this.modalHandler(inputName);
  };

  formateDate = (date) => {
    let formattedDate;

    if (date) {
      const dateArray = date.split('/');
      const [month, year] = dateArray;

      formattedDate = new Date(year, +month - 1);
    } else {
      formattedDate = new Date('1900-12-17T03:24:00');
    }

    return formattedDate;
  };

  updateStateHandler = (value: string, name: string, id: string) => {
    const { additionalBerufList } = this.state;

    const updatedState = additionalBerufList.map((beruf: any) => {
      let newBeruf = beruf;
      if (id === beruf?._id) {
        newBeruf = {
          ...beruf,
          [name]: value,
        };
      }
      return newBeruf;
    });

    this.setState({
      additionalBerufList: updatedState,
    });
  };

  updateProfileHandler = () => {
    const { user, updateUserRequest, showResponse } = this.props;
    const {
      additionalBerufList,
      name,
      company,
      description,
      website,
      employmentType,
      activity,
      careerLevel,
      industry,
      domain,
      start,
      end,
    } = this.state;

    const newBeruf = [
      {
        name: name.value,
        company: company.value,
        description: description.value,
        website: website.value,
        employmentType,
        activity,
        careerLevel,
        industry,
        domain,
        start,
        end,
      },
      ...additionalBerufList.map((el: any) => {
        if (el) {
          return {
            start: el.start,
            end: el.end,
            company: el.company,
            name: el.name,
            industry: el.industry,
            description: el.description,
          };
        }
      }),
    ];

    const updatedUser = {
      ...user,
      password: '',
      beruf: newBeruf,
    };

    if (user && user.name) {
      updateUserRequest(updatedUser);
    } else if (user && !user.name) {
      showResponse({
        message: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
      });
    }
  };

  addBerufHandler = () => {
    const { user } = this.props;
    if (user) {
      const newBeruf = {
        _id: Math.random().toString(),
        name: '',
        company: '',
        industry: '',
        start: '',
        end: '',
      };

      this.setState({
        additionalBerufList: [...this.state.additionalBerufList, newBeruf],
      });
    }
  };

  // REMOVE BERUF HANDLER
  removeBerufHandler = (id: string) => {
    const { user, updateUserRequest } = this.props;

    if (user && user.beruf) {
      const updatedBeruf: Array<any> = user.beruf.filter((beruf: any) => beruf?._id !== id);

      this.setState({
        additionalBerufList: updatedBeruf,
      });
      updateUserRequest({
        ...user,
        beruf: updatedBeruf,
      });
    }
  };

  deleteItemHandler = (value: string) => {
    const newActivities = this.state.activity.filter((el) => el !== value);

    this.setState({
      ...this.state,
      activity: newActivities,
    });
  };

  render(): JSX.Element {
    const { classes, theme, userIsLoading, config, language } = this.props;
    const { profileProfession, btn } = language;
    const bereichType = this.state.industry ? specialityType[this.state.industry] : null;

    const { start, end } = this.state;
    const formattedStartDate = dateToString(start, 'MM.YYYY');
    const formattedEndDate = dateToString(end, 'MM.YYYY', 'Bis heute');

    return (
      <React.Fragment>
        <div className={classes.container}>
          <ProfileScale />
          <div className={classes.berufWrapper}>
            <BerufHeading title={profileProfession.positionTitleLabel} />
            <div className={classes.inputsWrapper}>
              <Input
                placeholder={profileProfession.jobTitle}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, 'name')}
                autoCapitalize="none"
                value={this.state.name.value}
                isFocused={this.state.name.isFocused}
                onBlur={() => this.focusHandler('name')}
                onFocus={() => this.focusHandler('name')}
              />
              {config.APP_FULL_NAME !== 'MeinP3' &&
                config.APP_NAME !== 'begabungslust' &&
                config.APP_NAME !== 'sherides' && (
                  <>
                    <TouchableInput
                      onClick={() => this.modalHandler('employmentType')}
                      value={this.state.employmentType}
                      placeholder={profileProfession.employmentLabel}
                    />
                    {config.APP_NAME !== 'gdl' && (
                      <TouchableInput
                        onClick={() => this.modalHandler('activity')}
                        value={undefined}
                        placeholder={profileProfession.activityLabel}
                      />
                    )}
                    {this.state.activity.map((value) => (
                      <MultipleModalItem
                        item={value}
                        name="activity"
                        deleteItemHandler={() => this.deleteItemHandler(value)}
                      />
                    ))}
                    {config.APP_NAME !== 'eventus' && (
                      <TouchableInput
                        onClick={() => this.modalHandler('careerLevel')}
                        value={this.state.careerLevel}
                        placeholder={profileProfession.careerStageLabel}
                      />
                    )}
                  </>
                )}
              <Input
                placeholder={profileProfession.activityDescLabel}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, 'description')}
                autoCapitalize="none"
                value={this.state.description.value}
                isFocused={this.state.description.isFocused}
                onBlur={() => this.focusHandler('description')}
                onFocus={() => this.focusHandler('description')}
              />
            </div>
          </div>
          {config.APP_FULL_NAME !== 'MeinP3' &&
            config.APP_NAME !== 'begabungslust' &&
            config.APP_NAME !== 'sherides' && (
              <>
                <div className={classes.berufWrapper}>
                  <BerufHeading title={profileProfession.companyLabel} />
                  <div className={classes.inputsWrapper}>
                    <Input
                      placeholder={profileProfession.nameLabel}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, 'company')}
                      autoCapitalize="none"
                      value={this.state.company.value}
                      isFocused={this.state.company.isFocused}
                      onBlur={() => this.focusHandler('company')}
                      onFocus={() => this.focusHandler('company')}
                    />
                    {config.APP_NAME !== 'gdl' && (
                      <TouchableInput
                        onClick={() => this.modalHandler('industry')}
                        value={this.state.industry}
                        placeholder={profileProfession.industryLabel}
                      />
                    )}
                    {this.state.industry && this.state.industry !== 'Keine' && (
                      <TouchableInput
                        onClick={() => this.modalHandler('domain')}
                        value={this.state.domain}
                        placeholder={profileProfession.areaLabel}
                      />
                    )}
                    {config.APP_NAME === 'dphv' || config.APP_NAME === 'gdl' ? null : (
                      <Input
                        placeholder={profileProfession.websiteLabel}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, 'website')}
                        autoCapitalize="none"
                        value={this.state.website.value}
                        isFocused={this.state.website.isFocused}
                        onBlur={() => this.focusHandler('website')}
                        onFocus={() => this.focusHandler('website')}
                      />
                    )}
                  </div>
                </div>
                <div className={classes.berufWrapper}>
                  <BerufHeading title={profileProfession.employmentPeriodLabel} />
                  <div className={classes.inputsWrapper}>
                    <TouchableInput
                      onClick={() => this.modalHandler('start')}
                      value={formattedStartDate}
                      placeholder={profileProfession.fromLabel}
                    />
                    <div className={classes.vonDateWrapper} style={{ paddingTop: this.state.end ? 0 : 10 }}>
                      <div className={classes.vonDateButtonWrapper} style={{ top: this.state.end ? 0 : -10 }}>
                        <ButtonBase className={classes.vonDateButton} onClick={this.setDefaultDate}>
                          <span className={classes.vonDateLabel} style={{ color: theme.BUTTON_PRIMARY }}>
                            {profileProfession.untilToday}
                          </span>
                        </ButtonBase>
                      </div>
                      <TouchableInput
                        onClick={() => this.modalHandler('end')}
                        value={formattedEndDate}
                        placeholder={profileProfession.toLabel}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

          <div className={classes.inputsWrapper}>
            {this.state.additionalBerufList?.length > 0 &&
              this.state.additionalBerufList.map((beruf: any, i: number) => {
                const lastElem = i + 1 === this.state.additionalBerufList.length;

                return beruf?._id ? (
                  <Beruf
                    beruf={beruf}
                    elemIndex={i}
                    lastElem={lastElem}
                    key={i}
                    removeBerufHandler={this.removeBerufHandler}
                    changeBerufHandler={this.updateStateHandler}
                  />
                ) : null;
              })}
          </div>
          <div className={classes.updateButtonWrapper}>
            <Button label="+" fontSize="22px" width="50px" onClick={this.addBerufHandler} />
            <Button label={btn.saveBtn} onClick={this.updateProfileHandler} />
          </div>
        </div>
        <DatePicker
          date={new Date()}
          dateModalIsOpen={this.state.jobStartModalIsOpen}
          dateHandler={this.dateHandler}
          closeModalHandler={() => this.modalHandler('start')}
          pickerType="month"
          inputName="start"
          // Date picker config
          dateFormat="MM/yyyy"
          maxDate={new Date()}
          showMonthYearPicker
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          fixedHeight={true}
          startOpen={true}
          inline
          shouldCloseOnSelect={false}
        />
        <DatePicker
          date={new Date()}
          dateModalIsOpen={this.state.jobEndModalIsOpen}
          dateHandler={this.dateHandler}
          closeModalHandler={() => this.modalHandler('end')}
          pickerType="month"
          inputName="end"
          // Date picker config
          dateFormat="MM/yyyy"
          maxDate={new Date()}
          // minDate={minDate}
          showMonthYearPicker
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          fixedHeight={true}
          startOpen={true}
          inline
          shouldCloseOnSelect={false}
        />
        <SelectModal
          modalIsOpen={this.state.beschaeftigungsartModalIsOpen}
          modalTitle={profileProfession.employmentLabel}
          modalHandler={() => this.modalHandler('employmentType')}
          selectItemHandler={this.selectItemHandler}
          data={employmentType}
          modalName="employmentType"
        />
        {/* <SelectModal
          modalIsOpen={this.state.taetigkeitsfeldModalIsOpen}
          modalTitle={profileProfession.activityLabel}
          modalHandler={() => this.modalHandler('activity')}
          selectItemHandler={this.selectItemHandler}
          data={activityType}
          modalName="activity"
        /> */}
        <MultipleSelectModal
          showModal={this.state.taetigkeitsfeldModalIsOpen}
          modalTitle={profileProfession.activityLabel}
          modalHandler={() => this.modalHandler('activity')}
          selectItemHandler={(value) =>
            this.setState({
              ...this.state,
              activity: [...this.state.activity, value],
            })
          }
          deleteItemHandler={this.deleteItemHandler}
          data={activityType}
          values={this.state.activity}
        />

        <SelectModal
          modalIsOpen={this.state.karrierestufeModalIsOpen}
          modalTitle={profileProfession.careerStageLabel}
          modalHandler={() => this.modalHandler('careerLevel')}
          selectItemHandler={this.selectItemHandler}
          data={seniorityType}
          modalName="careerLevel"
        />
        <SelectModal
          modalIsOpen={this.state.brancheModalIsOpen}
          modalTitle={profileProfession.industryLabel}
          modalHandler={() => this.modalHandler('industry')}
          selectItemHandler={this.selectItemHandler}
          data={branchType}
          modalName="industry"
        />
        <SelectModal
          modalIsOpen={this.state.bereichModalIsOpen}
          modalTitle={profileProfession.areaLabel}
          modalHandler={() => this.modalHandler('domain')}
          selectItemHandler={this.selectItemHandler}
          data={bereichType || [{ label: 'Kein Bereich', id: 'Kein Bereich' }]}
          modalName="domain"
        />
        <Loader showLoader={userIsLoading} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ users }) => ({
  userIsLoading: users.userIsLoading,
  user: users.user,
});

const mapDispatchToProps = {
  updateUserRequest,
  showResponse,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withLanguage(withConfig(ProfileBeruf))));
