import React, { useState } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { withConfig } from '../../config';
import { postImagePath, eventImagePath } from '../../utilities';
import ImageLightBox from './ImageLightBox/ImageLightBox';
import { getConfig } from 'config/config';
import { ImageGrid } from 'components/UI/ImageGrid';

import { TMediaSize } from './AttachmentsGallery';

import { cn } from 'utilities/utils';
import useWindowDimensions from 'hooks/useWindowDimensions';

const styles = createStyles({
  container: {},
  galleryWrapper: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  galleryBackdrop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    padding: '20px',
    borderRadius: '50px',
    zIndex: 0,
    background: 'rgba(0, 0, 0, .5)',
  },
  backdropTitle: {
    fontSize: 48,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  image: {
    width: '100%',
    objectFit: 'cover',
    maxHeight: '800px',
  },
  '@media(max-width: 650px)': {
    container: {
      width: '115%',
    },
  },
});

const { theme } = getConfig();

interface Props extends WithStyles<typeof styles> {
  images: Array<any>;
  commentImage: boolean;
  videos: any[];
  size: TMediaSize;
  showAsString?: boolean;
}

const PostGallery: React.FC<Props> = ({ classes, images, commentImage = false, videos, size, showAsString }) => {
  // const [width] = LayoutHook();
  // dont understand sense of it, replaced with undefined
  // const heightImagesContainer = width < 600 ? 400 : 500;
  const heightImagesContainer = undefined;
  const [showGallery, setShowGallery] = useState(false);
  const [imageIdx, setImageIdx] = useState<number>(0);

  const { mediaWidth } = useWindowDimensions();

  let galleryImagesList: Array<any> = [];
  let imagesList: Array<any> = [];
  let imagesGallery;

  if (images && !commentImage) {
    imagesList = images.map((image) => image?.thumb || image?.url);
    galleryImagesList = images.map((image) => ({ url: postImagePath('full', image.url || image), ...image }));
  } else if (images && commentImage) {
    imagesList = images.map((image) => image?.thumb || image?.url);
    galleryImagesList = images.map((image) => eventImagePath(image));
  }

  const galleryHandler = (galleryMode: boolean, idx?: number) => {
    setShowGallery(!showGallery);

    setImageIdx(idx);
  };

  const closeGalleryHandler = () => {
    setShowGallery(false);
  };

  const ImageAsString = ({ thumb, filename, idx }: { thumb: string; filename: string; idx: number }) => (
    <button className="flex flex-row items-center" onClick={() => galleryHandler(false, idx)}>
      <img className="w-20 h-20 rounded-lg" src={thumb} alt={`attachment ${idx + 1}`} />
      <span className="ml-4">{filename}</span>
    </button>
  );

  if (imagesList.length === 1) {
    if (showAsString) {
      imagesGallery = images.map((img, idx) => {
        return <ImageAsString key={img.url} thumb={img.thumb || img.url} filename={img.fileName} idx={idx} />;
      });
    } else
      imagesGallery = (
        <button
          onClick={() => galleryHandler(false)}
          style={{ width: mediaWidth[size], display: 'flex', justifyContent: 'center' }}
        >
          <div className={classes.galleryWrapper}>
            <img src={imagesList[0]} className={classes.image} />
          </div>
        </button>
      );
  } else if (imagesList.length > 1) {
    imagesGallery = (
      <div style={{ height: heightImagesContainer, width: '100%', minWidth: 100 }}>
        <ImageGrid images={imagesList} onClick={(image: string, idx: number) => galleryHandler(false, idx)} />
      </div>
    );
  }

  return (
    <div className={cn(classes.container, 'flex flex-row', showAsString && 'justify-start flex-col self-start mb-2')}>
      <ImageLightBox
        onClose={closeGalleryHandler}
        showGallery={showGallery}
        images={galleryImagesList}
        incomingIndex={imageIdx}
      />
      {imagesGallery}
    </div>
  );
};

export default withStyles(styles)(withConfig(PostGallery));
