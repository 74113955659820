import React, { useEffect, useState } from 'react';
import { Box, createStyles, Modal, WithStyles, withStyles } from '@material-ui/core';
import { getConfig } from 'config/config';
import Video from 'react-player';

import PdfFileIcon from 'assets/icons/PdfFileIcon';
import { deleteDocument, statisticDocuments } from 'api/documents';
import DefaultModal from 'components/Shared/DefaultModal';
import Button from 'components/UI/Button';
import { useLanguage } from 'languages/languageContext';
import LightboxGallery from 'components/Shared/ImageLightBox/ImageLightBox';
import { IonIcon } from 'components/UI/IonIcon';
import { downloadFile } from '../../../utilities/downloadFile';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/shadcn/Dropdown';
import toast from 'react-hot-toast';

const { config, theme } = getConfig();
const styles = createStyles({
  container: {
    flex: 1,
    position: 'relative',
    width: '95%',
    alignItems: 'center',
    margin: '0 auto',
    marginBottom: 10,
    alignSelf: 'center',
    border: '1px solid',
    borderRadius: 10,
    padding: 10,
    borderColor: theme.BACKGROUND_SECONDARY,
  },
  fileWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    borderColor: theme.BACKGROUND_SECONDARY,
  },
  iconWrapper: {
    marginRight: 20,
  },
  title: {
    flex: 5,
    marginRight: 25,
  },
  uploaderName: {
    fontSize: 14,
    fontFamily: 'Roboto',
  },
  date: {
    fontSize: 14,
    fontFamily: 'Roboto',
    fontStyle: 'italic',
  },
  fileName: {
    fontSize: 14,
    wordBreak: 'break-word',
    fontFamily: 'Roboto',
  },
  threeDots: {
    height: 45,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 10px',
    width: 50,
    borderRadius: 50,
    flexDirection: 'column',
    display: 'flex',
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: 6,
    backgroundColor: theme.BUTTON_SECONDARY,
  },
  buttonText: {
    fontSize: 16,
  },
});

interface Props extends WithStyles<typeof styles> {
  document: any;
  handleDeleteDocument: (id: string) => void;
  showFolderModal: (id: string, title: string) => void;
  showDeleteButton: boolean;
  owner?: boolean;
  setShowLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

const DocumentsGalleryItem: React.FC<Props> = (props) => {
  const { classes, document, handleDeleteDocument, showFolderModal, showDeleteButton, owner, setShowLoading } = props;
  const { downloads, eventLabels } = useLanguage();
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fileExtention, setFileExtention] = useState('');
  const [currentFile, setCurrentFile] = useState(null);
  const fileUri = document.path.includes('http')
    ? { uri: document.path }
    : { uri: `${config.SERVER_PATH}/${document.path}` };

  useEffect(() => {
    if (document.mimetype) {
      if (document.mimetype.includes('pdf')) return setFileExtention('pdf');
      if (document.mimetype.includes('image')) return setFileExtention('image');
      if (document.mimetype.includes('video')) return setFileExtention('video');
      return setFileExtention('file');
    }
    const extention = document.filename.split('.');
    const lastElement = extention.length;
    setFileExtention(extention[lastElement - 1]);
  }, []);

  const setFileIcon = (fileExtention: string) => {
    if (fileExtention === 'image') {
      return <img alt={document.filename} src={fileUri.uri} style={{ width: 40, height: 40 }} />;
    }
    if (fileExtention === 'pdf') {
      if (document.thumb) return <img alt="PDF" src={document.thumb} style={{ width: 40, height: 40 }} />;
      else return <PdfFileIcon fill={theme.BUTTON_ICON} />;
    }
    if (fileExtention === 'video') {
      return <Video url={fileUri.uri} width={40} height={40} playing={false} />;
    }
    if (fileExtention === 'file') {
      return <IonIcon name="document-outline" size={40} />;
    }

    return <div>{fileExtention}</div>;
  };

  const showFormattedDate = (document: any) => {
    const date = new Date(document.createdAt);
    return date.toLocaleDateString();
  };

  const deleteDocumentHandler = () => {
    deleteDocument(document._id).then(() => {
      handleDeleteDocument(document._id);
    });
    setShowDeleteModal(false);
  };

  const onCloseCurrent = () => {
    setShowModal(false);
    setCurrentFile(null);
  };

  const downloadFileHandler = async () => {
    setShowLoading(true);
    downloadFile(document.path, document.filename, fileExtention === 'pdf').finally(() => setShowLoading(false));
    await statisticDocuments(document?._id, 'downloaded');
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(document.path);
      toast.success('Link kopiert');
    } catch (err) {
      console.log('Error copy link ' + err.message);
    }
  };
  const validDocument = {
    uploader:
      document.uploader?.name && document.uploader?.lastname
        ? document.uploader
        : {
            name: 'Unkown',
            lastname: '',
          },
    filename: document.filename || 'Unknown',
  };

  const RenderFileView = () => {
    if (fileExtention === 'pdf') return <></>;
    if (fileExtention === 'file') return <></>;
    if (fileExtention === 'image')
      return (
        <LightboxGallery
          images={[{ url: document.path }]}
          incomingIndex={0}
          showGallery={showModal}
          onClose={onCloseCurrent}
        />
      );
    if (fileExtention === 'video')
      return (
        <Modal
          style={{
            zIndex: 10,
            position: 'absolute',
            width: '50%',
            height: '50%',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: 'auto',
          }}
          open={showModal}
          onClose={onCloseCurrent}
        >
          <Video width={'100%'} height={'100%'} url={document.path} controls playing />
        </Modal>
      );
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.fileWrapper}>
          <div
            onClick={async () => {
              if (fileExtention === 'pdf') return window.open(document.path);
              if (fileExtention === 'file') return downloadFileHandler();
              setCurrentFile(document);
              setShowModal(true);
              await statisticDocuments(document._id, 'seen');
            }}
            style={{ height: '100%' }}
            className="w-full flex-row flex"
          >
            <div className={classes.iconWrapper}>{setFileIcon(fileExtention)}</div>
            <div className={classes.title}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <span className={classes.uploaderName} style={{ color: theme.TEXT_PRIMARY }}>
                  {validDocument.uploader.name} {validDocument.uploader.lastname}
                </span>
                <span className={classes.date} style={{ color: theme.TEXT_SECONDARY }}>
                  {showFormattedDate(document)}
                </span>
              </div>
              <span className={classes.fileName} style={{ color: theme.BUTTON_PRIMARY }}>
                {validDocument.filename}
              </span>
            </div>
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Box className={classes.threeDots}>
                <div className={classes.dot} />
                <div className={classes.dot} />
                <div className={classes.dot} />
              </Box>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="-mt-2 mr-16 sm:mr-12 md:mr-14 lg:mr-28 w-40">
              <DropdownMenuGroup>
                {owner && (
                  <DropdownMenuItem onClick={() => showFolderModal(document._id, document.filename)}>
                    <span className={classes.buttonText}>{downloads.rename}</span>
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem onClick={downloadFileHandler}>
                  <span className={classes.buttonText}>{downloads.downloadFile}</span>
                </DropdownMenuItem>
                {(showDeleteButton || owner) && (
                  <DropdownMenuItem
                    onClick={() => {
                      setCurrentFile(document);
                      setShowDeleteModal(true);
                    }}
                  >
                    <span className={classes.buttonText}>{downloads.delete}</span>
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem onClick={handleCopyLink}>
                  <span className={classes.buttonText}>{eventLabels.copyLinkLabel}</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      {currentFile && <RenderFileView />}
      {showDeleteModal && (
        <DefaultModal
          showModal={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          modalName="deleteDocumentModal"
          modalTitle="Löschen eines Dokuments"
          message="Diese Datei löschen?"
        >
          <Button
            label="Abbrechen"
            background={theme.BUTTON_SECONDARY}
            onClick={() => {
              setCurrentFile(null);
              setShowDeleteModal(false);
            }}
          />
          <Button label="Löschen" onClick={deleteDocumentHandler} />
        </DefaultModal>
      )}
    </React.Fragment>
  );
};

export default withStyles(styles)(DocumentsGalleryItem);
